.news_list {
	.list_table {
		width: 1000px;

		th.title {
			width: 20%;
		}

		th.description {
			width: 20%;
		}

		th.tags {
			width: 20%;
		}

		th.status {
			width: 10%;
		}

		th.create {
			width: 15%;
		}

		th.post {
			width: 15%;
		}

		.list_row {
			td {
				.inner {
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
				}
			}
		}
	}
}
