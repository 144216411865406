.ugc_detail {
	.media_list_container {
		margin-top: 5vh;

		color: #747475;
		font-weight: bold;

		.media_item_list {
      margin-top: 3vh;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			gap: 10px;

			.media_item {
				position: relative;
				flex-basis: calc((100% - 30px) / 4);

        cursor: pointer;

				.image_container {
					> video {
						display: block;
						width: 100%;
					}

					> img {
						display: block;
						width: 100%;
					}
				}

				&.selected {
					&::after {
						content: "設定中";
						position: absolute;
						top: 10px;
						left: 10px;
						background-color: rgba(0, 0, 0, 0.7);
						border-radius: 4px;
						padding: 5px;
						color: #ffffff;
						font-size: 13px;
						font-weight: 600;
					}
				}

        &:hover {
          opacity: 0.7;
        }
			}
		}
	}
}
