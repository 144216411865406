.order_list {
  .list_table {
    width: 100%;

    > thead {
      > tr {
        > th.order_cart_id {
          width: 150px;
        }

        > th.order_number {
          width: 200px;
        }

        > th.order_status {
          width: 150px;
        }

        > th.order_route {
          width: 150px;
        }

        > th.order_name {
          width: 200px;
        }

        > th.order_postal_code {
          width: 150px;
        }

        > th.order_address {
          width: 250px;
        }

        > th.order_phone_number {
          width: 170px;
        }

        > th.order_mail {
          width: 250px;
        }

        > th.order_date {
          width: 170px;
        }

        > th.order_total {
          width: 150px;
        }
      }
    }
  }
}
