.order_detail {
  .return_order_list_container {
    background-color: #ff4747;
    color: #ffffff;
    border-radius: 10px;
    padding: 15px;
    margin-top: 20px;
    width: 100%;
    box-sizing: border-box;

    > p {
      font-weight: 600;
      margin: 0;
    }

    .cart_id_container {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      > div {
        &::after {
          content: ",";
          margin-right: 5px;
        }

        &:last-child {
          &::after {
            content: "";
          }
        }
      }
    }
  }

  .order_flex {
    display: flex;
    flex-direction: row;

    .order_detail_container {
      flex-basis: 60%;
    }

    .shipped_cancel_button {
      background-color: #0000ff;
    }

    .refund_button_container {
      margin-top: 5vh;
      text-align: center;
    }

    .cancel_button_container {
      margin-top: 5vh;
      text-align: center;
    }

    .order_product_container {
      flex-basis: 40%;
      margin-left: 20px;

      .product_item {
        margin-top: 5vh;

        border-radius: 8px;
        border: 1px solid #efefef;

        padding: 20px;

        > .item_count {
          text-align: right;
        }

        > table {
          width: 100%;

          &:nth-of-type(n + 2) {
            margin-top: 30px;
          }

          th,
          td {
            padding: 10px;
          }

          th {
            width: 30%;
            text-align: left;
          }
        }

        .product_button_container {
          margin-top: 5vh;
          text-align: center;
        }

        .product_refund_checkbox_container {
          margin-top: 5vh;
          font-size: 0.8em;

          > label {
            display: flex;
            flex-direction: row;
            align-items: center;
          }
        }

        .product_cancel_checkbox_container {
          margin-top: 5vh;
          font-size: 0.8em;

          > label {
            display: flex;
            flex-direction: row;
            align-items: center;
          }
        }
      }

      .order_history_list_container {
        margin-top: 5vh;
        border-radius: 8px;
        border: 1px solid #efefef;

        padding: 20px;

        .order_history_title {
          width: 100%;
          text-align: center;

          padding: 20px;
          box-sizing: border-box;
          font-weight: bold;
        }

        .history_container {
          border-radius: 8px;
          border: 1px solid #efefef;

          padding: 20px;

          margin-bottom: 2vh;

          > table > tbody > tr > th {
            text-align: left;
            width: 40%;
          }

          .change_container {
            border-radius: 8px;
            border: 1px solid #efefef;

            margin-top: 1vh;
            padding: 20px;

            > table > tbody > tr > th {
              text-align: left;
              width: 40%;
            }
          }
        }
      }
    }
  }
}
