.admin_user_detail {
	.detail_table {
		.permission_list_container {
			padding-top: 10px;
			padding-bottom: 10px;

			.permission_container {
				.application_name {
				}

				.permission_type {
					padding-left: 10px;
				}

				.no_permission {
					color: #000000;
				}

				.read_permission {
					color: green;
				}

				.write_permission {
					color: blue;
				}
			}
		}
	}
}
