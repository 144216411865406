.stock_list {
  .list_table {
    width: 1000px;

    > thead {
      > tr {
        > th.name {
          width: 300px;
        }

        > th.warehousing_id {
          width: 200px;
        }

        > th.warehousing_date {
          width: 150px;
        }

        > th.created_at {
          width: 150px;
        }

        > th.stock {
          width: 150px;
        }

        > th.arrival_status {
          width: auto;
        }
      }
    }

    .list_row {
      .control_col {
        cursor: default;
      }
    }
  }
}
