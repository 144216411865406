.setting {
  text-align: center;

  .random_password_button_container {
    text-align: right;
  }

  > .title {
    margin-top: 10vh;
    font-size: 24px;
    font-weight: 600;
  }

  > form {
    margin-top: 10vh;

    > table {
      margin: auto;
    }

    > input[type="submit"] {
      margin-top: 10vh;
    }

    .password_attention {
      margin-top: 16px;
      font-size: 14px;
    }
  }

  > .logout_button_container {
    margin-top: 10vh;
  }
}
