.coupon_list {
	.list_table {
    width: 1000px;

		th.code {
			width: 16.6%;
		}

		th.name {
			width: 16.6%;
		}

		th.discount {
			width: 16.6%;
		}

		th.status {
			width: 16.6%;
		}

		th.start {
			width: 16.6%
		}

		th.expiration {
			width: 16.6%;
		}

		th.create {
			width: 16.6%;
		}
	}
}
