.gift_edit {
	> form {
		> table {
			width: 80%;

			&:nth-of-type(n + 2) {
				margin-top: 30px;
			}

			th,
			td {
				padding: 10px;

				.label_form {
					margin-top: 10px;
				}
			}

			th {
				width: 25%;
				text-align: left;
			}
		}
	}
}
