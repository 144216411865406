.review_list {
  .list_table {
    width: 1000px;

    th.title {
      width: 200px;
    }

    th.name {
      width: 150px;
    }

    th.score {
      width: 100px;
    }

    th.text {
      width: 200px;
    }

    th.group {
      width: 150px;
    }

    th.status {
      width: 150px;
    }

    th.check {
      width: 100px;
    }

    th.create {
      width: 300px;
    }

    .list_row {
      td {
        .inner {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        }
      }
    }
  }
}
