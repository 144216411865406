.toggle_button {
	-webkit-appearance: none;
	appearance: none;
	background-color: #0c0c0d1a;
	border-radius: 1em;
	height: 2em; 
	width: 4em;
	cursor: pointer;
}

.toggle_button:hover {
	background-color: #0c0c0d33;
	border-color: #0c0c0d7f;
}

.toggle_button:active {
	background-color: #0c0c0d4d;
}

.toggle_button:focus {
	box-shadow: 0 0 0 2px #66cdaa inset, 0 0 0 2px #66cdaa, 0 0 0 2px #66cdaa;
}

.toggle_button:checked {
	background-color: #66cdaa;
	border-color: #66cdaa;
}
  
.toggle_button::before {
	background-color: currentColor;
	border-radius: 1em;
	content: "";
	display: inline-block;
	height: 2em;
	transition: margin-left .2s cubic-bezier(.07, .95, 0, 1);
	width: 2em;
	box-shadow: 0 10px 25px 0 rgba(0, 0, 0, .5);
}

.toggle_button:checked::before {
	background-color: #ffffff;
	margin-left: 2.125em;
}