.selected_items {
  display: inline-block;
  margin: 10px 20px 10px 0;
  padding: 10px;
  border: solid 1px #f2f3f4;
  border-radius: 10px;

  .item_name {
    font-size: 16px;
    margin: 0 10px 0 0;
  }

  .delete_button {
    display: inline-block;
    position: relative;
    width: 15px;
    height: 15px;
    background: #fff; /* ボタンの背景色 */
    cursor: pointer;
  }

  .delete_button::before,
  .delete_button::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1px; /* 棒の幅（太さ） */
    height: 15px; /* 棒の高さ */
    background: #333; /* バツ印の色 */
  }
  .delete_button::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  .delete_button::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}
