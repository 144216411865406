.list_table_container {
  .list_table {
    thead {
      th {
        &:nth-of-type(n + 2) {
          position: relative;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            width: 1px;
            height: 80%;
            background-color: #ffffff;
            transform: translateY(-50%);
          }
        }
      }

      .id {
        width: 150px;
      }

      .label {
        width: 150px;
      }

      .name {
        width: 200px;
      }

      .shpments_id {
        width: auto;
      }

      .create {
        width: 200px;
      }
    }
  }
}
