.layout {
  width: 100vw;
  height: 100vh;

  .header {
    height: 64px;
    box-sizing: border-box;
    z-index: 10;
    background: #13243f;
    color: #ffffff;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    .title_container {
      display: flex;
      flex-direction: row;
      align-items: center;

      > a {
        > img {
          display: inline-block;
          width: 150px;
          height: auto;
        }
        
      }

      > span {
        font-size: 20px;
        margin-left: 10px;
      }
    }

    .user_container {
      font-size: 16px;

      .name {
        font-weight: 400;
        font-size: 0.8em;
      }

      > a {
        text-decoration: none;
        color: #ffffff;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }

  .menu {
    display: flex;
    flex-direction: row;
    height: calc(100% - 64px);
    box-sizing: border-box;

    > nav {
      flex-basis: 15%;

      padding: 1%;

      background: #60697b;

      overflow-y: scroll;

      > ul {
        list-style: none;
        padding: 0;
        margin: 0;

        > li {
          padding-left: 5%;
          display: flex;
          align-items: center;
          height: 50px;

          font-size: 14px;
          font-weight: bold;
          color: #ffffff;

          border-radius: 4px;

          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }

        > .selected {
          background: #13243f;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        }
      }
    }

    > div {
      flex-basis: 85%;
      padding: 3%;
      overflow-y: scroll;
      color: #747475;
    }
  }
}
