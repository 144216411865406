.offline_sore_order_detail {
  .offline_store_order_flex {
    display: flex;
    flex-direction: row;

    .offline_store_order_detail_container {
      flex-basis: 60%;
      .detail_table {
        width: 100%;
        border-collapse: collapse;

        th,
        td {
          padding: 10px;
          border-bottom: 1px solid #efefef;
        }

        th {
          width: 30%;
          text-align: left;
        }
      }
    }

    .shipped_cancel_button {
      background-color: #0000ff;
    }

    .refund_button_container {
      margin-top: 5vh;
      text-align: center;
    }

    .cancel_button_container {
      margin-top: 5vh;
      text-align: center;
    }

    .offline_store_order_item_container {
      flex-basis: 40%;
      margin-left: 20px;

      .item {
        margin-top: 5vh;

        border-radius: 8px;
        border: 1px solid #efefef;

        padding: 20px;

        > .item_count {
          text-align: right;
        }

        > table {
          width: 100%;

          &:nth-of-type(n + 2) {
            margin-top: 30px;
          }

          th,
          td {
            padding: 10px;
          }

          th {
            width: 30%;
            text-align: left;
          }
        }

        .item_button_container {
          margin-top: 5vh;
          text-align: center;
          .design_button {
            margin: 10px;
          }
        }
      }
    }
  }
}
