.pager {
	display: flex;
	flex-direction: row;

	max-width: 480px;
	width: 80vw;
	height: 50px;
	margin: auto;

	border: 1px solid #c5cad0;
	box-sizing: border-box;
	border-radius: 4px;

	font-weight: 400;
	font-size: 16px;

	> .select_container {
		flex-basis: 60%;
		position: relative;

		border-left: 1px solid #c5cad0;
		border-right: 1px solid #c5cad0;

		.context {
			position: absolute;
			text-align: center;
			top: 50%;
			left: 50%;
			transform: translateY(-50%) translateX(-50%);
		}

		> i {
			position: absolute;
			top: 50%;
			right: 10%;
			transform: translateY(-50%);
		}

		> select {
			appearance: none;
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			width: 100%;
			height: 100%;
			cursor: pointer;
			font-size: 16px;
		}
	}

	> button {
		flex-basis: 20%;
		background: none;
		border: none;

		cursor: pointer;
	}
}
