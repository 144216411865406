.server_status {
	text-align: center;

	> .title {
		margin-top: 10vh;
		font-size: 24px;
		font-weight: 600;
	}

	> .current_status {
		margin-top: 5vh;

		> span {
			font-size: 1.2em;
			font-weight: 700;
		}
	}

	> .checkbox_container {
		margin-top: 5vh;
	}

	> .select_container {
		margin-top: 5vh;

		:nth-child(n + 2) {
			margin-left: 3vw;
		}
	}

	> .button_container {
		margin-top: 10vh;
	}
}
