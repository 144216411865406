.sale_list {
	.list_table {
		width: 1000px;

		th.name {
			width: 20%;
		}

		th.coupon {
			width: 20%;
		}

		th.start {
			width: 20%;
		}

		th.end {
			width: 20%;
		}

		th.status {
			width: 20%;
		}
	}
}
