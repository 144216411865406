.ugc_edit {
	> form {
		> table {
			width: 80%;

			&:nth-of-type(n + 2) {
				margin-top: 30px;
			}

			th,
			td {
				padding: 10px;
			}

			th {
				width: 25%;
				text-align: left;

				> p {
					margin: 0;
					font-size: 0.5em;
					color: crimson;
				}
			}
		}
	}

	.edit_caution {
		margin-top: 5vh;
		text-align: center;
	}
}
