.login {
	text-align: center;

	color: #60697b;

	> .title {
		margin-top: 20vh;
		font-size: 24px;
		font-weight: 600;
	}
	> form {
		margin-top: 10vh;

		> table {
			margin: auto;
    }
    
    > input[type="submit"] {
      margin-top: 10vh;
    }
	}
}
