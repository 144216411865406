.coupon_detail {
	.coupon_flex {
		display: flex;
		flex-direction: row;

		> .coupon_detail_container {
			flex-basis: 60%;
		}

		> .coupon_order_container {
      flex-basis: 40%;
      margin-left: 20px;
		}

		.coupon_order_item {
			margin-top: 5vh;

			border-radius: 8px;
			border: 1px solid #efefef;

			padding: 20px;

			> table {
				width: 100%;

				&:nth-of-type(n + 2) {
					margin-top: 30px;
				}

				th,
				td {
					padding: 10px;
				}

				th {
					width: 30%;
					text-align: left;
				}
			}

			.product_button_container {
				margin-top: 5vh;
				text-align: center;
			}
		}
	}
}
