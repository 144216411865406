.ugc_media_edit {
	padding-top: 10vh;

	.image_container {
		margin: auto;
		width: 50%;
		max-width: 400px;

		> img {
			display: block;
			width: 100%;
		}

		> video {
			display: block;
			width: 100%;
		}
	}

	.ugc_item {
		tr {
			th {
				button {
					margin-left: 5px;
					background: none;
					border: 1px solid #000000;
					border-radius: 8px;
					padding: 5px 10px;

					cursor: pointer;

					&:active {
						opacity: 0.6;
					}
				}
			}
		}
	}
	.button_container {
	}
}
