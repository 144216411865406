.list_table {
  .id {
    width: 10%;
  }
  .store_names {
    width: 20%;
  }
  .discount_rate {
    width: 5%;
  }
  .start_at {
    width: 32.5%;
  }
  .end_at {
    width: 32.5%;
  }
}
