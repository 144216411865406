.offline_store_list {
  .list_table {
    width: 1000px;

    th.id {
      width: 33%;
    }

    th.name {
      width: 33%;
    }

    th.create {
      width: 33%;
    }

    .list_row {
      td {
        .inner {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        }
      }
    }
  }
}
