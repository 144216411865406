.order_product_edit {
  form {
    table {
      width: 80%;

      &:nth-of-type(n + 2) {
        margin-top: 30px;
      }

      th,
      td {
        padding: 10px;

        > .design_input {
          width: 170px;
        }

				> .design_select {
					width: 140px;
				}
      }

      th {
        width: 25%;
        text-align: left;
      }
    }
  }
}
