.faq_detail {
	.faq_flex {
		.faq_detail_container {
			> table {
				td {
					padding: 20px;
				}
			}
			.faq_detail_anwer {
				padding-top: 3vh;
				padding-bottom: 3vh;
			}
		}
	}
}
