.faq_list {
	.list_table {
		width: 1000px;

		> thead {
			> tr {
				height: 5vh;
				white-space: normal;

				> th.manage_id {
					width: 20%;
				}

				> th.name {
					width: 40%;
				}

				> th.is_open {
					width: 20%;
				}

				> th.priority {
					width: 20%;
				}
			}
		}
	}
}
