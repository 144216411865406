.faq_create {
	.faq_answer_container {
		display: flex;
		flex-direction: row;

		justify-content: space-between;

		> div {
			flex-basis: 49%;
		}

		> textarea {
			flex-basis: 49%;
			resize: none;
			box-sizing: border-box;
		}

		.faq_detail_anwer_preview {
			border-radius: 20px;
			border: 1px solid #f2f3f4;
			box-sizing: border-box;
			padding: 20px;
		}
	}
	> form {
		> table {
			width: 80%;

			&:nth-of-type(n + 2) {
				margin-top: 30px;
			}

			th,
			td {
				padding: 10px;
			}

			th {
				width: 25%;
				text-align: left;
			}
		}
	}
}
