.ugc_media_select {
	padding-top: 10vh;

	.image_container {
		margin: auto;
		width: 50%;
		max-width: 400px;

		> img {
			display: block;
			width: 100%;
		}

		> video {
			display: block;
			width: 100%;
		}
	}

	.button_container {
	}
}
