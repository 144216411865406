body {
	margin: 0;
	font-family: "Avenir Next", Verdana, "Yu Gothic Medium", "游ゴシック Medium",
		YuGothic, 游ゴシック体, "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	.list {
		.filter_form {
			display: flex;
			flex-direction: row;

			> table {
				flex-basis: 80%;
				> tbody {
					th,
					td {
						padding: 10px;
					}

					th {
						width: 20%;
						text-align: left;
					}

					td {
						> input[type="radio"] {
							&:nth-of-type(n + 2) {
								margin-left: 15px;
							}
						}

						> .design_input {
							width: 40%;
						}
					}
				}
			}

			> .search_button_container {
				display: flex;
				flex-direction: column-reverse;
				justify-content: space-between;
				align-items: center;
				flex-basis: 20%;
			}
		}

		.list_table_container {
			overflow-x: scroll;
		}

		.list_table {
			min-width: 100%;
			margin-top: 10vh;
			table-layout: fixed;
			word-wrap: break-word;

			border-collapse: collapse;

			> thead {
				background: #606b7d;
				color: #ffffff;
				font-weight: 600;
				font-size: 15px;

				> tr {
					> th {
						text-align: center;
						height: 10vh;
					}

					> th.edit {
						width: 10%;
					}
				}
			}

			> tbody {
				> tr {
					height: 10vh;

					&:hover {
						font-weight: 600;
					}

					> td {
						padding: 10px;
						text-align: center;

						> a {
							text-decoration: none;
							background: #606b7d;
							border-radius: 16px;
							padding: 12px 19px;
							cursor: pointer;
							font-weight: 600;
							font-size: 16px;
							color: #ffffff;
						}
					}

					&:nth-of-type(even) {
						background: #f2f3f4;
					}
				}

				.list_row {
					cursor: pointer;
				}
			}
		}

		.pager_container {
			margin-top: 10vh;
		}
	}

	.detail {
		.detail_table {
			margin-top: 5vh;
			width: 100%;

			border-collapse: collapse;

			> tbody {
				> tr {
					border: 1px solid #efefef;
					height: 7vh;

					> th {
						width: 25%;
						background: #f7f7f7;
					}

					> td {
						padding-left: 5%;
					}
				}
			}
		}

		.button_container {
			margin-top: 5vh;

			text-align: center;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			gap: 20px;
		}

		> .list_back_container {
			a {
				color: #606b7d;
			}
		}
	}

	.edit,
	.create {
		.edit_form,
		.create_form {
			> table {
				width: 100%;

				&:nth-of-type(n + 2) {
					margin-top: 30px;
				}

				th,
				td {
					padding: 10px;
				}

				th {
					width: 20%;
					text-align: left;
				}
			}
		}

		.button_container {
			margin-top: 5vh;

			text-align: center;

			> button {
				&:nth-of-type(n + 2) {
					margin-left: 15px;
				}
			}
		}
	}

	.design_input {
		width: 100%;
		box-sizing: border-box;
		padding: 9px 18px;
		background: #f2f3f4;
		border-radius: 10px;
		font-weight: 400;
		font-size: 14px;
		color: #747475;
		transition: all 0.2s ease-out;
		border: none;

		&:focus {
			outline: none;
		}

		&:disabled {
			opacity: 0.7;
		}
	}

	.design_select {
		width: 40%;
		box-sizing: border-box;
		padding: 9px 18px;
		background: #f2f3f4;
		border-radius: 10px;
		font-weight: 400;
		font-size: 14px;
		color: #747475;
		transition: all 0.2s ease-out;
		border: none;
		margin-right: 15px;
	}

	.design_button {
		border: none;
		background: #606b7d;
		border-radius: 16px;
		padding: 12px 19px;
		cursor: pointer;
		font-weight: 600;
		font-size: 16px;
		color: #ffffff;

		&:focus {
			outline: none;
		}

		&:disabled {
			background: #c0c5cf;
		}
	}

	.create_button {
		background: #efac29;
	}

	.delete_button {
		background: #ff4747;
	}

	.radio_button {
		margin-right: 8px;
		white-space: nowrap;
	}
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
