.product_item {
  border: 1px solid #efefef;
  border-radius: 10px;
  margin: 20px;
  padding: 10px;

  > tbody {
    > tr {
      > th {
        width: 350px !important;
        text-align: center !important;
      }
    }
  }
}
