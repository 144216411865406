.faq_list {
	.list_table {
		width: 1000px;

		> thead {
			> tr {
				height: 5vh;
				white-space: normal;

				> th.manage_id {
					width: 15%;
				}

				> th.question {
					width: 25%;
				}

				> th.answer {
					width: 20%;
				}

				> th.category {
					width: 20%;
				}

				> th.is_open {
					width: 10%;
				}

				> th.priority {
					width: 10%;
				}
			}
		}
	}
}
