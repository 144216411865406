.gift_detail {
	.gift_flex {
		display: flex;
		flex-direction: row;

		.gift_detail_container {
			flex-basis: 60%;
		}

		.refund_button_container {
			margin-top: 5vh;
			text-align: center;
		}

		.gift_product_container {
			flex-basis: 40%;
			margin-left: 20px;

			.product_item {
				margin-top: 5vh;

				border-radius: 8px;
				border: 1px solid #efefef;

				padding: 20px;

				> .item_count {
					text-align: right;
				}

				> table {
					width: 100%;

					&:nth-of-type(n + 2) {
						margin-top: 30px;
					}

					th,
					td {
						padding: 10px;
					}

					th {
						width: 30%;
						text-align: left;
					}
				}

				.product_button_container {
					margin: 5vh 0;
					text-align: center;

					> button {
						margin: 0 1vw;
					}
				}

				.product_refund_checkbox_container {
					margin-top: 5vh;
					font-size: 0.8em;

					> label {
						display: flex;
						flex-direction: row;
						align-items: center;
					}
				}
			}
		}
	}
}
