.offline_store_order_list {
  .list_table {
    width: 1000px;
    overflow-x: scroll;

    th.slip_number {
      width: 150px;
    }

    th.order_number {
      width: 200px;
    }

    th.name {
      width: 200px;
    }

    th.store_name {
      width: 150px;
    }

    th.create {
      width: 200px;
    }

    th.shipment_date {
      width: 200px;
    }

    th.shipment_time {
      width: 150px;
    }

    th.tracking_number {
      width: 200px;
    }

    th.status {
      width: 200px;
    }

    th.total_amount {
      width: 150px;
    }

    .list_row {
      td {
        .inner {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        }
      }
    }
  }
}
