.notice_list {
	.list_table {
		width: 1000px;

		th.title {
			width: 20%;
		}

		th.name {
			width: 20%;
		}

		th.score {
			width: 10%;
		}

		th.text {
			width: 30%;
		}

		th.status {
			width: 20%;
		}

		th.create {
			width: 20%;
		}

		.list_row {
			td {
				.inner {
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 3;
					overflow: hidden;
				}
			}
		}
	}
}
