.edit_modal {
	> .overlay {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 2;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.7);
	}

	> .container {
		position: fixed;
		top: 20px;
		left: 20px;
		right: 20px;
		bottom: 20px;

		padding: 50px;
		border-radius: 20px;
		background: #ffffff;

		z-index: 3;

		overflow: scroll;

		> .close_button {
			position: absolute;
			top: 20px;
			right: 20px;
			cursor: pointer;
		}
	}
}
