.business_contact_list {
  .list_table {
    width: 1000px;

    th.id {
      width: 5%;
    }

    th.name {
      width: 21.25%;
    }

    th.corporation {
      width: 21.25%;
    }

    th.mail {
      width: 21.25%;
    }

    th.status {
      width: 10%;
    }

    th.create {
      width: 21.25%;
    }
  }
}
